export default {
  "slots": {
    "base": [
      "rounded-md font-medium inline-flex items-center focus:outline-none disabled:cursor-not-allowed aria-disabled:cursor-not-allowed disabled:opacity-75 aria-disabled:opacity-75",
      "transition-colors"
    ],
    "label": "truncate",
    "leadingIcon": "shrink-0",
    "leadingAvatar": "shrink-0",
    "trailingIcon": "shrink-0"
  },
  "variants": {
    "buttonGroup": {
      "horizontal": "not-only:first:rounded-e-none not-only:last:rounded-s-none not-last:not-first:rounded-none",
      "vertical": "not-only:first:rounded-b-none not-only:last:rounded-t-none not-last:not-first:rounded-none"
    },
    "color": {
      "primary": "",
      "error": "",
      "red": "",
      "orange": "",
      "amber": "",
      "yellow": "",
      "lime": "",
      "green": "",
      "emerald": "",
      "teal": "",
      "cyan": "",
      "sky": "",
      "blue": "",
      "indigo": "",
      "violet": "",
      "purple": "",
      "fuchsia": "",
      "pink": "",
      "rose": "",
      "gray": ""
    },
    "variant": {
      "solid": "",
      "outline": "",
      "soft": "",
      "subtle": "",
      "ghost": "",
      "link": ""
    },
    "size": {
      "xs": {
        "base": "px-2 py-1 text-xs gap-1",
        "leadingIcon": "size-4",
        "trailingIcon": "size-4"
      },
      "sm": {
        "base": "px-2.5 py-1.5 text-xs gap-1.5",
        "leadingIcon": "size-4",
        "trailingIcon": "size-4"
      },
      "md": {
        "base": "px-2.5 py-1.5 text-sm gap-1.5",
        "leadingIcon": "size-5",
        "trailingIcon": "size-5"
      },
      "lg": {
        "base": "px-3 py-2 text-sm gap-2",
        "leadingIcon": "size-5",
        "trailingIcon": "size-5"
      },
      "xl": {
        "base": "px-3 py-2 text-base gap-2",
        "leadingIcon": "size-6",
        "trailingIcon": "size-6"
      }
    },
    "block": {
      "true": {
        "base": "w-full",
        "trailingIcon": "ms-auto"
      }
    },
    "square": {
      "true": ""
    },
    "leading": {
      "true": ""
    },
    "trailing": {
      "true": ""
    },
    "loading": {
      "true": ""
    }
  },
  "compoundVariants": [
    {
      "color": "primary" as const,
      "variant": "solid" as const,
      "class": "text-white dark:text-gray-900 bg-primary-500 hover:bg-primary-600 disabled:bg-primary-500 aria-disabled:bg-primary-500 dark:bg-primary-400 dark:hover:bg-primary-500 dark:disabled:bg-primary-400 dark:aria-disabled:bg-primary-400 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-500 dark:focus-visible:outline-primary-400"
    },
    {
      "color": "error" as const,
      "variant": "solid" as const,
      "class": "text-white dark:text-gray-900 bg-error-500 hover:bg-error-600 disabled:bg-error-500 aria-disabled:bg-error-500 dark:bg-error-400 dark:hover:bg-error-500 dark:disabled:bg-error-400 dark:aria-disabled:bg-error-400 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-error-500 dark:focus-visible:outline-error-400"
    },
    {
      "color": "red" as const,
      "variant": "solid" as const,
      "class": "text-white dark:text-gray-900 bg-red-500 hover:bg-red-600 disabled:bg-red-500 aria-disabled:bg-red-500 dark:bg-red-400 dark:hover:bg-red-500 dark:disabled:bg-red-400 dark:aria-disabled:bg-red-400 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-500 dark:focus-visible:outline-red-400"
    },
    {
      "color": "orange" as const,
      "variant": "solid" as const,
      "class": "text-white dark:text-gray-900 bg-orange-500 hover:bg-orange-600 disabled:bg-orange-500 aria-disabled:bg-orange-500 dark:bg-orange-400 dark:hover:bg-orange-500 dark:disabled:bg-orange-400 dark:aria-disabled:bg-orange-400 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-500 dark:focus-visible:outline-orange-400"
    },
    {
      "color": "amber" as const,
      "variant": "solid" as const,
      "class": "text-white dark:text-gray-900 bg-amber-500 hover:bg-amber-600 disabled:bg-amber-500 aria-disabled:bg-amber-500 dark:bg-amber-400 dark:hover:bg-amber-500 dark:disabled:bg-amber-400 dark:aria-disabled:bg-amber-400 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-amber-500 dark:focus-visible:outline-amber-400"
    },
    {
      "color": "yellow" as const,
      "variant": "solid" as const,
      "class": "text-white dark:text-gray-900 bg-yellow-500 hover:bg-yellow-600 disabled:bg-yellow-500 aria-disabled:bg-yellow-500 dark:bg-yellow-400 dark:hover:bg-yellow-500 dark:disabled:bg-yellow-400 dark:aria-disabled:bg-yellow-400 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-500 dark:focus-visible:outline-yellow-400"
    },
    {
      "color": "lime" as const,
      "variant": "solid" as const,
      "class": "text-white dark:text-gray-900 bg-lime-500 hover:bg-lime-600 disabled:bg-lime-500 aria-disabled:bg-lime-500 dark:bg-lime-400 dark:hover:bg-lime-500 dark:disabled:bg-lime-400 dark:aria-disabled:bg-lime-400 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-lime-500 dark:focus-visible:outline-lime-400"
    },
    {
      "color": "green" as const,
      "variant": "solid" as const,
      "class": "text-white dark:text-gray-900 bg-green-500 hover:bg-green-600 disabled:bg-green-500 aria-disabled:bg-green-500 dark:bg-green-400 dark:hover:bg-green-500 dark:disabled:bg-green-400 dark:aria-disabled:bg-green-400 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-500 dark:focus-visible:outline-green-400"
    },
    {
      "color": "emerald" as const,
      "variant": "solid" as const,
      "class": "text-white dark:text-gray-900 bg-emerald-500 hover:bg-emerald-600 disabled:bg-emerald-500 aria-disabled:bg-emerald-500 dark:bg-emerald-400 dark:hover:bg-emerald-500 dark:disabled:bg-emerald-400 dark:aria-disabled:bg-emerald-400 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-500 dark:focus-visible:outline-emerald-400"
    },
    {
      "color": "teal" as const,
      "variant": "solid" as const,
      "class": "text-white dark:text-gray-900 bg-teal-500 hover:bg-teal-600 disabled:bg-teal-500 aria-disabled:bg-teal-500 dark:bg-teal-400 dark:hover:bg-teal-500 dark:disabled:bg-teal-400 dark:aria-disabled:bg-teal-400 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-500 dark:focus-visible:outline-teal-400"
    },
    {
      "color": "cyan" as const,
      "variant": "solid" as const,
      "class": "text-white dark:text-gray-900 bg-cyan-500 hover:bg-cyan-600 disabled:bg-cyan-500 aria-disabled:bg-cyan-500 dark:bg-cyan-400 dark:hover:bg-cyan-500 dark:disabled:bg-cyan-400 dark:aria-disabled:bg-cyan-400 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-cyan-500 dark:focus-visible:outline-cyan-400"
    },
    {
      "color": "sky" as const,
      "variant": "solid" as const,
      "class": "text-white dark:text-gray-900 bg-sky-500 hover:bg-sky-600 disabled:bg-sky-500 aria-disabled:bg-sky-500 dark:bg-sky-400 dark:hover:bg-sky-500 dark:disabled:bg-sky-400 dark:aria-disabled:bg-sky-400 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-500 dark:focus-visible:outline-sky-400"
    },
    {
      "color": "blue" as const,
      "variant": "solid" as const,
      "class": "text-white dark:text-gray-900 bg-blue-500 hover:bg-blue-600 disabled:bg-blue-500 aria-disabled:bg-blue-500 dark:bg-blue-400 dark:hover:bg-blue-500 dark:disabled:bg-blue-400 dark:aria-disabled:bg-blue-400 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500 dark:focus-visible:outline-blue-400"
    },
    {
      "color": "indigo" as const,
      "variant": "solid" as const,
      "class": "text-white dark:text-gray-900 bg-indigo-500 hover:bg-indigo-600 disabled:bg-indigo-500 aria-disabled:bg-indigo-500 dark:bg-indigo-400 dark:hover:bg-indigo-500 dark:disabled:bg-indigo-400 dark:aria-disabled:bg-indigo-400 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500 dark:focus-visible:outline-indigo-400"
    },
    {
      "color": "violet" as const,
      "variant": "solid" as const,
      "class": "text-white dark:text-gray-900 bg-violet-500 hover:bg-violet-600 disabled:bg-violet-500 aria-disabled:bg-violet-500 dark:bg-violet-400 dark:hover:bg-violet-500 dark:disabled:bg-violet-400 dark:aria-disabled:bg-violet-400 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-violet-500 dark:focus-visible:outline-violet-400"
    },
    {
      "color": "purple" as const,
      "variant": "solid" as const,
      "class": "text-white dark:text-gray-900 bg-purple-500 hover:bg-purple-600 disabled:bg-purple-500 aria-disabled:bg-purple-500 dark:bg-purple-400 dark:hover:bg-purple-500 dark:disabled:bg-purple-400 dark:aria-disabled:bg-purple-400 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-purple-500 dark:focus-visible:outline-purple-400"
    },
    {
      "color": "fuchsia" as const,
      "variant": "solid" as const,
      "class": "text-white dark:text-gray-900 bg-fuchsia-500 hover:bg-fuchsia-600 disabled:bg-fuchsia-500 aria-disabled:bg-fuchsia-500 dark:bg-fuchsia-400 dark:hover:bg-fuchsia-500 dark:disabled:bg-fuchsia-400 dark:aria-disabled:bg-fuchsia-400 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-fuchsia-500 dark:focus-visible:outline-fuchsia-400"
    },
    {
      "color": "pink" as const,
      "variant": "solid" as const,
      "class": "text-white dark:text-gray-900 bg-pink-500 hover:bg-pink-600 disabled:bg-pink-500 aria-disabled:bg-pink-500 dark:bg-pink-400 dark:hover:bg-pink-500 dark:disabled:bg-pink-400 dark:aria-disabled:bg-pink-400 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-pink-500 dark:focus-visible:outline-pink-400"
    },
    {
      "color": "rose" as const,
      "variant": "solid" as const,
      "class": "text-white dark:text-gray-900 bg-rose-500 hover:bg-rose-600 disabled:bg-rose-500 aria-disabled:bg-rose-500 dark:bg-rose-400 dark:hover:bg-rose-500 dark:disabled:bg-rose-400 dark:aria-disabled:bg-rose-400 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-rose-500 dark:focus-visible:outline-rose-400"
    },
    {
      "color": "primary" as const,
      "variant": "outline" as const,
      "class": "ring ring-inset ring-primary-500/50 dark:ring-primary-400/50 text-primary-500 dark:text-primary-400 hover:bg-primary-500/10 disabled:bg-transparent aria-disabled:bg-transparent dark:hover:bg-primary-400/10 dark:disabled:bg-transparent dark:aria-disabled:bg-transparent focus-visible:ring-2 focus-visible:ring-primary-500 dark:focus-visible:ring-primary-400"
    },
    {
      "color": "error" as const,
      "variant": "outline" as const,
      "class": "ring ring-inset ring-error-500/50 dark:ring-error-400/50 text-error-500 dark:text-error-400 hover:bg-error-500/10 disabled:bg-transparent aria-disabled:bg-transparent dark:hover:bg-error-400/10 dark:disabled:bg-transparent dark:aria-disabled:bg-transparent focus-visible:ring-2 focus-visible:ring-error-500 dark:focus-visible:ring-error-400"
    },
    {
      "color": "red" as const,
      "variant": "outline" as const,
      "class": "ring ring-inset ring-red-500/50 dark:ring-red-400/50 text-red-500 dark:text-red-400 hover:bg-red-500/10 disabled:bg-transparent aria-disabled:bg-transparent dark:hover:bg-red-400/10 dark:disabled:bg-transparent dark:aria-disabled:bg-transparent focus-visible:ring-2 focus-visible:ring-red-500 dark:focus-visible:ring-red-400"
    },
    {
      "color": "orange" as const,
      "variant": "outline" as const,
      "class": "ring ring-inset ring-orange-500/50 dark:ring-orange-400/50 text-orange-500 dark:text-orange-400 hover:bg-orange-500/10 disabled:bg-transparent aria-disabled:bg-transparent dark:hover:bg-orange-400/10 dark:disabled:bg-transparent dark:aria-disabled:bg-transparent focus-visible:ring-2 focus-visible:ring-orange-500 dark:focus-visible:ring-orange-400"
    },
    {
      "color": "amber" as const,
      "variant": "outline" as const,
      "class": "ring ring-inset ring-amber-500/50 dark:ring-amber-400/50 text-amber-500 dark:text-amber-400 hover:bg-amber-500/10 disabled:bg-transparent aria-disabled:bg-transparent dark:hover:bg-amber-400/10 dark:disabled:bg-transparent dark:aria-disabled:bg-transparent focus-visible:ring-2 focus-visible:ring-amber-500 dark:focus-visible:ring-amber-400"
    },
    {
      "color": "yellow" as const,
      "variant": "outline" as const,
      "class": "ring ring-inset ring-yellow-500/50 dark:ring-yellow-400/50 text-yellow-500 dark:text-yellow-400 hover:bg-yellow-500/10 disabled:bg-transparent aria-disabled:bg-transparent dark:hover:bg-yellow-400/10 dark:disabled:bg-transparent dark:aria-disabled:bg-transparent focus-visible:ring-2 focus-visible:ring-yellow-500 dark:focus-visible:ring-yellow-400"
    },
    {
      "color": "lime" as const,
      "variant": "outline" as const,
      "class": "ring ring-inset ring-lime-500/50 dark:ring-lime-400/50 text-lime-500 dark:text-lime-400 hover:bg-lime-500/10 disabled:bg-transparent aria-disabled:bg-transparent dark:hover:bg-lime-400/10 dark:disabled:bg-transparent dark:aria-disabled:bg-transparent focus-visible:ring-2 focus-visible:ring-lime-500 dark:focus-visible:ring-lime-400"
    },
    {
      "color": "green" as const,
      "variant": "outline" as const,
      "class": "ring ring-inset ring-green-500/50 dark:ring-green-400/50 text-green-500 dark:text-green-400 hover:bg-green-500/10 disabled:bg-transparent aria-disabled:bg-transparent dark:hover:bg-green-400/10 dark:disabled:bg-transparent dark:aria-disabled:bg-transparent focus-visible:ring-2 focus-visible:ring-green-500 dark:focus-visible:ring-green-400"
    },
    {
      "color": "emerald" as const,
      "variant": "outline" as const,
      "class": "ring ring-inset ring-emerald-500/50 dark:ring-emerald-400/50 text-emerald-500 dark:text-emerald-400 hover:bg-emerald-500/10 disabled:bg-transparent aria-disabled:bg-transparent dark:hover:bg-emerald-400/10 dark:disabled:bg-transparent dark:aria-disabled:bg-transparent focus-visible:ring-2 focus-visible:ring-emerald-500 dark:focus-visible:ring-emerald-400"
    },
    {
      "color": "teal" as const,
      "variant": "outline" as const,
      "class": "ring ring-inset ring-teal-500/50 dark:ring-teal-400/50 text-teal-500 dark:text-teal-400 hover:bg-teal-500/10 disabled:bg-transparent aria-disabled:bg-transparent dark:hover:bg-teal-400/10 dark:disabled:bg-transparent dark:aria-disabled:bg-transparent focus-visible:ring-2 focus-visible:ring-teal-500 dark:focus-visible:ring-teal-400"
    },
    {
      "color": "cyan" as const,
      "variant": "outline" as const,
      "class": "ring ring-inset ring-cyan-500/50 dark:ring-cyan-400/50 text-cyan-500 dark:text-cyan-400 hover:bg-cyan-500/10 disabled:bg-transparent aria-disabled:bg-transparent dark:hover:bg-cyan-400/10 dark:disabled:bg-transparent dark:aria-disabled:bg-transparent focus-visible:ring-2 focus-visible:ring-cyan-500 dark:focus-visible:ring-cyan-400"
    },
    {
      "color": "sky" as const,
      "variant": "outline" as const,
      "class": "ring ring-inset ring-sky-500/50 dark:ring-sky-400/50 text-sky-500 dark:text-sky-400 hover:bg-sky-500/10 disabled:bg-transparent aria-disabled:bg-transparent dark:hover:bg-sky-400/10 dark:disabled:bg-transparent dark:aria-disabled:bg-transparent focus-visible:ring-2 focus-visible:ring-sky-500 dark:focus-visible:ring-sky-400"
    },
    {
      "color": "blue" as const,
      "variant": "outline" as const,
      "class": "ring ring-inset ring-blue-500/50 dark:ring-blue-400/50 text-blue-500 dark:text-blue-400 hover:bg-blue-500/10 disabled:bg-transparent aria-disabled:bg-transparent dark:hover:bg-blue-400/10 dark:disabled:bg-transparent dark:aria-disabled:bg-transparent focus-visible:ring-2 focus-visible:ring-blue-500 dark:focus-visible:ring-blue-400"
    },
    {
      "color": "indigo" as const,
      "variant": "outline" as const,
      "class": "ring ring-inset ring-indigo-500/50 dark:ring-indigo-400/50 text-indigo-500 dark:text-indigo-400 hover:bg-indigo-500/10 disabled:bg-transparent aria-disabled:bg-transparent dark:hover:bg-indigo-400/10 dark:disabled:bg-transparent dark:aria-disabled:bg-transparent focus-visible:ring-2 focus-visible:ring-indigo-500 dark:focus-visible:ring-indigo-400"
    },
    {
      "color": "violet" as const,
      "variant": "outline" as const,
      "class": "ring ring-inset ring-violet-500/50 dark:ring-violet-400/50 text-violet-500 dark:text-violet-400 hover:bg-violet-500/10 disabled:bg-transparent aria-disabled:bg-transparent dark:hover:bg-violet-400/10 dark:disabled:bg-transparent dark:aria-disabled:bg-transparent focus-visible:ring-2 focus-visible:ring-violet-500 dark:focus-visible:ring-violet-400"
    },
    {
      "color": "purple" as const,
      "variant": "outline" as const,
      "class": "ring ring-inset ring-purple-500/50 dark:ring-purple-400/50 text-purple-500 dark:text-purple-400 hover:bg-purple-500/10 disabled:bg-transparent aria-disabled:bg-transparent dark:hover:bg-purple-400/10 dark:disabled:bg-transparent dark:aria-disabled:bg-transparent focus-visible:ring-2 focus-visible:ring-purple-500 dark:focus-visible:ring-purple-400"
    },
    {
      "color": "fuchsia" as const,
      "variant": "outline" as const,
      "class": "ring ring-inset ring-fuchsia-500/50 dark:ring-fuchsia-400/50 text-fuchsia-500 dark:text-fuchsia-400 hover:bg-fuchsia-500/10 disabled:bg-transparent aria-disabled:bg-transparent dark:hover:bg-fuchsia-400/10 dark:disabled:bg-transparent dark:aria-disabled:bg-transparent focus-visible:ring-2 focus-visible:ring-fuchsia-500 dark:focus-visible:ring-fuchsia-400"
    },
    {
      "color": "pink" as const,
      "variant": "outline" as const,
      "class": "ring ring-inset ring-pink-500/50 dark:ring-pink-400/50 text-pink-500 dark:text-pink-400 hover:bg-pink-500/10 disabled:bg-transparent aria-disabled:bg-transparent dark:hover:bg-pink-400/10 dark:disabled:bg-transparent dark:aria-disabled:bg-transparent focus-visible:ring-2 focus-visible:ring-pink-500 dark:focus-visible:ring-pink-400"
    },
    {
      "color": "rose" as const,
      "variant": "outline" as const,
      "class": "ring ring-inset ring-rose-500/50 dark:ring-rose-400/50 text-rose-500 dark:text-rose-400 hover:bg-rose-500/10 disabled:bg-transparent aria-disabled:bg-transparent dark:hover:bg-rose-400/10 dark:disabled:bg-transparent dark:aria-disabled:bg-transparent focus-visible:ring-2 focus-visible:ring-rose-500 dark:focus-visible:ring-rose-400"
    },
    {
      "color": "primary" as const,
      "variant": "soft" as const,
      "class": "text-primary-500 dark:text-primary-400 bg-primary-500/10 hover:bg-primary-500/15 focus-visible:bg-primary-500/15 disabled:bg-primary-500/10 aria-disabled:bg-primary-500/10 dark:bg-primary-400/10 dark:hover:bg-primary-400/15 dark:focus-visible:bg-primary-400/15 dark:disabled:bg-primary-400/10 dark:aria-disabled:bg-primary-400/10"
    },
    {
      "color": "error" as const,
      "variant": "soft" as const,
      "class": "text-error-500 dark:text-error-400 bg-error-500/10 hover:bg-error-500/15 focus-visible:bg-error-500/15 disabled:bg-error-500/10 aria-disabled:bg-error-500/10 dark:bg-error-400/10 dark:hover:bg-error-400/15 dark:focus-visible:bg-error-400/15 dark:disabled:bg-error-400/10 dark:aria-disabled:bg-error-400/10"
    },
    {
      "color": "red" as const,
      "variant": "soft" as const,
      "class": "text-red-500 dark:text-red-400 bg-red-500/10 hover:bg-red-500/15 focus-visible:bg-red-500/15 disabled:bg-red-500/10 aria-disabled:bg-red-500/10 dark:bg-red-400/10 dark:hover:bg-red-400/15 dark:focus-visible:bg-red-400/15 dark:disabled:bg-red-400/10 dark:aria-disabled:bg-red-400/10"
    },
    {
      "color": "orange" as const,
      "variant": "soft" as const,
      "class": "text-orange-500 dark:text-orange-400 bg-orange-500/10 hover:bg-orange-500/15 focus-visible:bg-orange-500/15 disabled:bg-orange-500/10 aria-disabled:bg-orange-500/10 dark:bg-orange-400/10 dark:hover:bg-orange-400/15 dark:focus-visible:bg-orange-400/15 dark:disabled:bg-orange-400/10 dark:aria-disabled:bg-orange-400/10"
    },
    {
      "color": "amber" as const,
      "variant": "soft" as const,
      "class": "text-amber-500 dark:text-amber-400 bg-amber-500/10 hover:bg-amber-500/15 focus-visible:bg-amber-500/15 disabled:bg-amber-500/10 aria-disabled:bg-amber-500/10 dark:bg-amber-400/10 dark:hover:bg-amber-400/15 dark:focus-visible:bg-amber-400/15 dark:disabled:bg-amber-400/10 dark:aria-disabled:bg-amber-400/10"
    },
    {
      "color": "yellow" as const,
      "variant": "soft" as const,
      "class": "text-yellow-500 dark:text-yellow-400 bg-yellow-500/10 hover:bg-yellow-500/15 focus-visible:bg-yellow-500/15 disabled:bg-yellow-500/10 aria-disabled:bg-yellow-500/10 dark:bg-yellow-400/10 dark:hover:bg-yellow-400/15 dark:focus-visible:bg-yellow-400/15 dark:disabled:bg-yellow-400/10 dark:aria-disabled:bg-yellow-400/10"
    },
    {
      "color": "lime" as const,
      "variant": "soft" as const,
      "class": "text-lime-500 dark:text-lime-400 bg-lime-500/10 hover:bg-lime-500/15 focus-visible:bg-lime-500/15 disabled:bg-lime-500/10 aria-disabled:bg-lime-500/10 dark:bg-lime-400/10 dark:hover:bg-lime-400/15 dark:focus-visible:bg-lime-400/15 dark:disabled:bg-lime-400/10 dark:aria-disabled:bg-lime-400/10"
    },
    {
      "color": "green" as const,
      "variant": "soft" as const,
      "class": "text-green-500 dark:text-green-400 bg-green-500/10 hover:bg-green-500/15 focus-visible:bg-green-500/15 disabled:bg-green-500/10 aria-disabled:bg-green-500/10 dark:bg-green-400/10 dark:hover:bg-green-400/15 dark:focus-visible:bg-green-400/15 dark:disabled:bg-green-400/10 dark:aria-disabled:bg-green-400/10"
    },
    {
      "color": "emerald" as const,
      "variant": "soft" as const,
      "class": "text-emerald-500 dark:text-emerald-400 bg-emerald-500/10 hover:bg-emerald-500/15 focus-visible:bg-emerald-500/15 disabled:bg-emerald-500/10 aria-disabled:bg-emerald-500/10 dark:bg-emerald-400/10 dark:hover:bg-emerald-400/15 dark:focus-visible:bg-emerald-400/15 dark:disabled:bg-emerald-400/10 dark:aria-disabled:bg-emerald-400/10"
    },
    {
      "color": "teal" as const,
      "variant": "soft" as const,
      "class": "text-teal-500 dark:text-teal-400 bg-teal-500/10 hover:bg-teal-500/15 focus-visible:bg-teal-500/15 disabled:bg-teal-500/10 aria-disabled:bg-teal-500/10 dark:bg-teal-400/10 dark:hover:bg-teal-400/15 dark:focus-visible:bg-teal-400/15 dark:disabled:bg-teal-400/10 dark:aria-disabled:bg-teal-400/10"
    },
    {
      "color": "cyan" as const,
      "variant": "soft" as const,
      "class": "text-cyan-500 dark:text-cyan-400 bg-cyan-500/10 hover:bg-cyan-500/15 focus-visible:bg-cyan-500/15 disabled:bg-cyan-500/10 aria-disabled:bg-cyan-500/10 dark:bg-cyan-400/10 dark:hover:bg-cyan-400/15 dark:focus-visible:bg-cyan-400/15 dark:disabled:bg-cyan-400/10 dark:aria-disabled:bg-cyan-400/10"
    },
    {
      "color": "sky" as const,
      "variant": "soft" as const,
      "class": "text-sky-500 dark:text-sky-400 bg-sky-500/10 hover:bg-sky-500/15 focus-visible:bg-sky-500/15 disabled:bg-sky-500/10 aria-disabled:bg-sky-500/10 dark:bg-sky-400/10 dark:hover:bg-sky-400/15 dark:focus-visible:bg-sky-400/15 dark:disabled:bg-sky-400/10 dark:aria-disabled:bg-sky-400/10"
    },
    {
      "color": "blue" as const,
      "variant": "soft" as const,
      "class": "text-blue-500 dark:text-blue-400 bg-blue-500/10 hover:bg-blue-500/15 focus-visible:bg-blue-500/15 disabled:bg-blue-500/10 aria-disabled:bg-blue-500/10 dark:bg-blue-400/10 dark:hover:bg-blue-400/15 dark:focus-visible:bg-blue-400/15 dark:disabled:bg-blue-400/10 dark:aria-disabled:bg-blue-400/10"
    },
    {
      "color": "indigo" as const,
      "variant": "soft" as const,
      "class": "text-indigo-500 dark:text-indigo-400 bg-indigo-500/10 hover:bg-indigo-500/15 focus-visible:bg-indigo-500/15 disabled:bg-indigo-500/10 aria-disabled:bg-indigo-500/10 dark:bg-indigo-400/10 dark:hover:bg-indigo-400/15 dark:focus-visible:bg-indigo-400/15 dark:disabled:bg-indigo-400/10 dark:aria-disabled:bg-indigo-400/10"
    },
    {
      "color": "violet" as const,
      "variant": "soft" as const,
      "class": "text-violet-500 dark:text-violet-400 bg-violet-500/10 hover:bg-violet-500/15 focus-visible:bg-violet-500/15 disabled:bg-violet-500/10 aria-disabled:bg-violet-500/10 dark:bg-violet-400/10 dark:hover:bg-violet-400/15 dark:focus-visible:bg-violet-400/15 dark:disabled:bg-violet-400/10 dark:aria-disabled:bg-violet-400/10"
    },
    {
      "color": "purple" as const,
      "variant": "soft" as const,
      "class": "text-purple-500 dark:text-purple-400 bg-purple-500/10 hover:bg-purple-500/15 focus-visible:bg-purple-500/15 disabled:bg-purple-500/10 aria-disabled:bg-purple-500/10 dark:bg-purple-400/10 dark:hover:bg-purple-400/15 dark:focus-visible:bg-purple-400/15 dark:disabled:bg-purple-400/10 dark:aria-disabled:bg-purple-400/10"
    },
    {
      "color": "fuchsia" as const,
      "variant": "soft" as const,
      "class": "text-fuchsia-500 dark:text-fuchsia-400 bg-fuchsia-500/10 hover:bg-fuchsia-500/15 focus-visible:bg-fuchsia-500/15 disabled:bg-fuchsia-500/10 aria-disabled:bg-fuchsia-500/10 dark:bg-fuchsia-400/10 dark:hover:bg-fuchsia-400/15 dark:focus-visible:bg-fuchsia-400/15 dark:disabled:bg-fuchsia-400/10 dark:aria-disabled:bg-fuchsia-400/10"
    },
    {
      "color": "pink" as const,
      "variant": "soft" as const,
      "class": "text-pink-500 dark:text-pink-400 bg-pink-500/10 hover:bg-pink-500/15 focus-visible:bg-pink-500/15 disabled:bg-pink-500/10 aria-disabled:bg-pink-500/10 dark:bg-pink-400/10 dark:hover:bg-pink-400/15 dark:focus-visible:bg-pink-400/15 dark:disabled:bg-pink-400/10 dark:aria-disabled:bg-pink-400/10"
    },
    {
      "color": "rose" as const,
      "variant": "soft" as const,
      "class": "text-rose-500 dark:text-rose-400 bg-rose-500/10 hover:bg-rose-500/15 focus-visible:bg-rose-500/15 disabled:bg-rose-500/10 aria-disabled:bg-rose-500/10 dark:bg-rose-400/10 dark:hover:bg-rose-400/15 dark:focus-visible:bg-rose-400/15 dark:disabled:bg-rose-400/10 dark:aria-disabled:bg-rose-400/10"
    },
    {
      "color": "primary" as const,
      "variant": "subtle" as const,
      "class": "text-primary-500 dark:text-primary-400 ring ring-inset ring-primary-500/25 dark:ring-primary-400/25 bg-primary-500/10 hover:bg-primary-500/15 disabled:bg-primary-500/10 aria-disabled:bg-primary-500/10 dark:bg-primary-400/10 dark:hover:bg-primary-400/15 dark:disabled:bg-primary-400/10 dark:aria-disabled:bg-primary-400/10 focus-visible:ring-2 focus-visible:ring-primary-500 dark:focus-visible:ring-primary-400"
    },
    {
      "color": "error" as const,
      "variant": "subtle" as const,
      "class": "text-error-500 dark:text-error-400 ring ring-inset ring-error-500/25 dark:ring-error-400/25 bg-error-500/10 hover:bg-error-500/15 disabled:bg-error-500/10 aria-disabled:bg-error-500/10 dark:bg-error-400/10 dark:hover:bg-error-400/15 dark:disabled:bg-error-400/10 dark:aria-disabled:bg-error-400/10 focus-visible:ring-2 focus-visible:ring-error-500 dark:focus-visible:ring-error-400"
    },
    {
      "color": "red" as const,
      "variant": "subtle" as const,
      "class": "text-red-500 dark:text-red-400 ring ring-inset ring-red-500/25 dark:ring-red-400/25 bg-red-500/10 hover:bg-red-500/15 disabled:bg-red-500/10 aria-disabled:bg-red-500/10 dark:bg-red-400/10 dark:hover:bg-red-400/15 dark:disabled:bg-red-400/10 dark:aria-disabled:bg-red-400/10 focus-visible:ring-2 focus-visible:ring-red-500 dark:focus-visible:ring-red-400"
    },
    {
      "color": "orange" as const,
      "variant": "subtle" as const,
      "class": "text-orange-500 dark:text-orange-400 ring ring-inset ring-orange-500/25 dark:ring-orange-400/25 bg-orange-500/10 hover:bg-orange-500/15 disabled:bg-orange-500/10 aria-disabled:bg-orange-500/10 dark:bg-orange-400/10 dark:hover:bg-orange-400/15 dark:disabled:bg-orange-400/10 dark:aria-disabled:bg-orange-400/10 focus-visible:ring-2 focus-visible:ring-orange-500 dark:focus-visible:ring-orange-400"
    },
    {
      "color": "amber" as const,
      "variant": "subtle" as const,
      "class": "text-amber-500 dark:text-amber-400 ring ring-inset ring-amber-500/25 dark:ring-amber-400/25 bg-amber-500/10 hover:bg-amber-500/15 disabled:bg-amber-500/10 aria-disabled:bg-amber-500/10 dark:bg-amber-400/10 dark:hover:bg-amber-400/15 dark:disabled:bg-amber-400/10 dark:aria-disabled:bg-amber-400/10 focus-visible:ring-2 focus-visible:ring-amber-500 dark:focus-visible:ring-amber-400"
    },
    {
      "color": "yellow" as const,
      "variant": "subtle" as const,
      "class": "text-yellow-500 dark:text-yellow-400 ring ring-inset ring-yellow-500/25 dark:ring-yellow-400/25 bg-yellow-500/10 hover:bg-yellow-500/15 disabled:bg-yellow-500/10 aria-disabled:bg-yellow-500/10 dark:bg-yellow-400/10 dark:hover:bg-yellow-400/15 dark:disabled:bg-yellow-400/10 dark:aria-disabled:bg-yellow-400/10 focus-visible:ring-2 focus-visible:ring-yellow-500 dark:focus-visible:ring-yellow-400"
    },
    {
      "color": "lime" as const,
      "variant": "subtle" as const,
      "class": "text-lime-500 dark:text-lime-400 ring ring-inset ring-lime-500/25 dark:ring-lime-400/25 bg-lime-500/10 hover:bg-lime-500/15 disabled:bg-lime-500/10 aria-disabled:bg-lime-500/10 dark:bg-lime-400/10 dark:hover:bg-lime-400/15 dark:disabled:bg-lime-400/10 dark:aria-disabled:bg-lime-400/10 focus-visible:ring-2 focus-visible:ring-lime-500 dark:focus-visible:ring-lime-400"
    },
    {
      "color": "green" as const,
      "variant": "subtle" as const,
      "class": "text-green-500 dark:text-green-400 ring ring-inset ring-green-500/25 dark:ring-green-400/25 bg-green-500/10 hover:bg-green-500/15 disabled:bg-green-500/10 aria-disabled:bg-green-500/10 dark:bg-green-400/10 dark:hover:bg-green-400/15 dark:disabled:bg-green-400/10 dark:aria-disabled:bg-green-400/10 focus-visible:ring-2 focus-visible:ring-green-500 dark:focus-visible:ring-green-400"
    },
    {
      "color": "emerald" as const,
      "variant": "subtle" as const,
      "class": "text-emerald-500 dark:text-emerald-400 ring ring-inset ring-emerald-500/25 dark:ring-emerald-400/25 bg-emerald-500/10 hover:bg-emerald-500/15 disabled:bg-emerald-500/10 aria-disabled:bg-emerald-500/10 dark:bg-emerald-400/10 dark:hover:bg-emerald-400/15 dark:disabled:bg-emerald-400/10 dark:aria-disabled:bg-emerald-400/10 focus-visible:ring-2 focus-visible:ring-emerald-500 dark:focus-visible:ring-emerald-400"
    },
    {
      "color": "teal" as const,
      "variant": "subtle" as const,
      "class": "text-teal-500 dark:text-teal-400 ring ring-inset ring-teal-500/25 dark:ring-teal-400/25 bg-teal-500/10 hover:bg-teal-500/15 disabled:bg-teal-500/10 aria-disabled:bg-teal-500/10 dark:bg-teal-400/10 dark:hover:bg-teal-400/15 dark:disabled:bg-teal-400/10 dark:aria-disabled:bg-teal-400/10 focus-visible:ring-2 focus-visible:ring-teal-500 dark:focus-visible:ring-teal-400"
    },
    {
      "color": "cyan" as const,
      "variant": "subtle" as const,
      "class": "text-cyan-500 dark:text-cyan-400 ring ring-inset ring-cyan-500/25 dark:ring-cyan-400/25 bg-cyan-500/10 hover:bg-cyan-500/15 disabled:bg-cyan-500/10 aria-disabled:bg-cyan-500/10 dark:bg-cyan-400/10 dark:hover:bg-cyan-400/15 dark:disabled:bg-cyan-400/10 dark:aria-disabled:bg-cyan-400/10 focus-visible:ring-2 focus-visible:ring-cyan-500 dark:focus-visible:ring-cyan-400"
    },
    {
      "color": "sky" as const,
      "variant": "subtle" as const,
      "class": "text-sky-500 dark:text-sky-400 ring ring-inset ring-sky-500/25 dark:ring-sky-400/25 bg-sky-500/10 hover:bg-sky-500/15 disabled:bg-sky-500/10 aria-disabled:bg-sky-500/10 dark:bg-sky-400/10 dark:hover:bg-sky-400/15 dark:disabled:bg-sky-400/10 dark:aria-disabled:bg-sky-400/10 focus-visible:ring-2 focus-visible:ring-sky-500 dark:focus-visible:ring-sky-400"
    },
    {
      "color": "blue" as const,
      "variant": "subtle" as const,
      "class": "text-blue-500 dark:text-blue-400 ring ring-inset ring-blue-500/25 dark:ring-blue-400/25 bg-blue-500/10 hover:bg-blue-500/15 disabled:bg-blue-500/10 aria-disabled:bg-blue-500/10 dark:bg-blue-400/10 dark:hover:bg-blue-400/15 dark:disabled:bg-blue-400/10 dark:aria-disabled:bg-blue-400/10 focus-visible:ring-2 focus-visible:ring-blue-500 dark:focus-visible:ring-blue-400"
    },
    {
      "color": "indigo" as const,
      "variant": "subtle" as const,
      "class": "text-indigo-500 dark:text-indigo-400 ring ring-inset ring-indigo-500/25 dark:ring-indigo-400/25 bg-indigo-500/10 hover:bg-indigo-500/15 disabled:bg-indigo-500/10 aria-disabled:bg-indigo-500/10 dark:bg-indigo-400/10 dark:hover:bg-indigo-400/15 dark:disabled:bg-indigo-400/10 dark:aria-disabled:bg-indigo-400/10 focus-visible:ring-2 focus-visible:ring-indigo-500 dark:focus-visible:ring-indigo-400"
    },
    {
      "color": "violet" as const,
      "variant": "subtle" as const,
      "class": "text-violet-500 dark:text-violet-400 ring ring-inset ring-violet-500/25 dark:ring-violet-400/25 bg-violet-500/10 hover:bg-violet-500/15 disabled:bg-violet-500/10 aria-disabled:bg-violet-500/10 dark:bg-violet-400/10 dark:hover:bg-violet-400/15 dark:disabled:bg-violet-400/10 dark:aria-disabled:bg-violet-400/10 focus-visible:ring-2 focus-visible:ring-violet-500 dark:focus-visible:ring-violet-400"
    },
    {
      "color": "purple" as const,
      "variant": "subtle" as const,
      "class": "text-purple-500 dark:text-purple-400 ring ring-inset ring-purple-500/25 dark:ring-purple-400/25 bg-purple-500/10 hover:bg-purple-500/15 disabled:bg-purple-500/10 aria-disabled:bg-purple-500/10 dark:bg-purple-400/10 dark:hover:bg-purple-400/15 dark:disabled:bg-purple-400/10 dark:aria-disabled:bg-purple-400/10 focus-visible:ring-2 focus-visible:ring-purple-500 dark:focus-visible:ring-purple-400"
    },
    {
      "color": "fuchsia" as const,
      "variant": "subtle" as const,
      "class": "text-fuchsia-500 dark:text-fuchsia-400 ring ring-inset ring-fuchsia-500/25 dark:ring-fuchsia-400/25 bg-fuchsia-500/10 hover:bg-fuchsia-500/15 disabled:bg-fuchsia-500/10 aria-disabled:bg-fuchsia-500/10 dark:bg-fuchsia-400/10 dark:hover:bg-fuchsia-400/15 dark:disabled:bg-fuchsia-400/10 dark:aria-disabled:bg-fuchsia-400/10 focus-visible:ring-2 focus-visible:ring-fuchsia-500 dark:focus-visible:ring-fuchsia-400"
    },
    {
      "color": "pink" as const,
      "variant": "subtle" as const,
      "class": "text-pink-500 dark:text-pink-400 ring ring-inset ring-pink-500/25 dark:ring-pink-400/25 bg-pink-500/10 hover:bg-pink-500/15 disabled:bg-pink-500/10 aria-disabled:bg-pink-500/10 dark:bg-pink-400/10 dark:hover:bg-pink-400/15 dark:disabled:bg-pink-400/10 dark:aria-disabled:bg-pink-400/10 focus-visible:ring-2 focus-visible:ring-pink-500 dark:focus-visible:ring-pink-400"
    },
    {
      "color": "rose" as const,
      "variant": "subtle" as const,
      "class": "text-rose-500 dark:text-rose-400 ring ring-inset ring-rose-500/25 dark:ring-rose-400/25 bg-rose-500/10 hover:bg-rose-500/15 disabled:bg-rose-500/10 aria-disabled:bg-rose-500/10 dark:bg-rose-400/10 dark:hover:bg-rose-400/15 dark:disabled:bg-rose-400/10 dark:aria-disabled:bg-rose-400/10 focus-visible:ring-2 focus-visible:ring-rose-500 dark:focus-visible:ring-rose-400"
    },
    {
      "color": "primary" as const,
      "variant": "ghost" as const,
      "class": "text-primary-500 dark:text-primary-400 hover:bg-primary-500/10 focus-visible:bg-primary-500/10 disabled:bg-transparent aria-disabled:bg-transparent dark:hover:bg-primary-400/10 dark:focus-visible:bg-primary-400/10 dark:disabled:bg-transparent dark:aria-disabled:bg-transparent"
    },
    {
      "color": "error" as const,
      "variant": "ghost" as const,
      "class": "text-error-500 dark:text-error-400 hover:bg-error-500/10 focus-visible:bg-error-500/10 disabled:bg-transparent aria-disabled:bg-transparent dark:hover:bg-error-400/10 dark:focus-visible:bg-error-400/10 dark:disabled:bg-transparent dark:aria-disabled:bg-transparent"
    },
    {
      "color": "red" as const,
      "variant": "ghost" as const,
      "class": "text-red-500 dark:text-red-400 hover:bg-red-500/10 focus-visible:bg-red-500/10 disabled:bg-transparent aria-disabled:bg-transparent dark:hover:bg-red-400/10 dark:focus-visible:bg-red-400/10 dark:disabled:bg-transparent dark:aria-disabled:bg-transparent"
    },
    {
      "color": "orange" as const,
      "variant": "ghost" as const,
      "class": "text-orange-500 dark:text-orange-400 hover:bg-orange-500/10 focus-visible:bg-orange-500/10 disabled:bg-transparent aria-disabled:bg-transparent dark:hover:bg-orange-400/10 dark:focus-visible:bg-orange-400/10 dark:disabled:bg-transparent dark:aria-disabled:bg-transparent"
    },
    {
      "color": "amber" as const,
      "variant": "ghost" as const,
      "class": "text-amber-500 dark:text-amber-400 hover:bg-amber-500/10 focus-visible:bg-amber-500/10 disabled:bg-transparent aria-disabled:bg-transparent dark:hover:bg-amber-400/10 dark:focus-visible:bg-amber-400/10 dark:disabled:bg-transparent dark:aria-disabled:bg-transparent"
    },
    {
      "color": "yellow" as const,
      "variant": "ghost" as const,
      "class": "text-yellow-500 dark:text-yellow-400 hover:bg-yellow-500/10 focus-visible:bg-yellow-500/10 disabled:bg-transparent aria-disabled:bg-transparent dark:hover:bg-yellow-400/10 dark:focus-visible:bg-yellow-400/10 dark:disabled:bg-transparent dark:aria-disabled:bg-transparent"
    },
    {
      "color": "lime" as const,
      "variant": "ghost" as const,
      "class": "text-lime-500 dark:text-lime-400 hover:bg-lime-500/10 focus-visible:bg-lime-500/10 disabled:bg-transparent aria-disabled:bg-transparent dark:hover:bg-lime-400/10 dark:focus-visible:bg-lime-400/10 dark:disabled:bg-transparent dark:aria-disabled:bg-transparent"
    },
    {
      "color": "green" as const,
      "variant": "ghost" as const,
      "class": "text-green-500 dark:text-green-400 hover:bg-green-500/10 focus-visible:bg-green-500/10 disabled:bg-transparent aria-disabled:bg-transparent dark:hover:bg-green-400/10 dark:focus-visible:bg-green-400/10 dark:disabled:bg-transparent dark:aria-disabled:bg-transparent"
    },
    {
      "color": "emerald" as const,
      "variant": "ghost" as const,
      "class": "text-emerald-500 dark:text-emerald-400 hover:bg-emerald-500/10 focus-visible:bg-emerald-500/10 disabled:bg-transparent aria-disabled:bg-transparent dark:hover:bg-emerald-400/10 dark:focus-visible:bg-emerald-400/10 dark:disabled:bg-transparent dark:aria-disabled:bg-transparent"
    },
    {
      "color": "teal" as const,
      "variant": "ghost" as const,
      "class": "text-teal-500 dark:text-teal-400 hover:bg-teal-500/10 focus-visible:bg-teal-500/10 disabled:bg-transparent aria-disabled:bg-transparent dark:hover:bg-teal-400/10 dark:focus-visible:bg-teal-400/10 dark:disabled:bg-transparent dark:aria-disabled:bg-transparent"
    },
    {
      "color": "cyan" as const,
      "variant": "ghost" as const,
      "class": "text-cyan-500 dark:text-cyan-400 hover:bg-cyan-500/10 focus-visible:bg-cyan-500/10 disabled:bg-transparent aria-disabled:bg-transparent dark:hover:bg-cyan-400/10 dark:focus-visible:bg-cyan-400/10 dark:disabled:bg-transparent dark:aria-disabled:bg-transparent"
    },
    {
      "color": "sky" as const,
      "variant": "ghost" as const,
      "class": "text-sky-500 dark:text-sky-400 hover:bg-sky-500/10 focus-visible:bg-sky-500/10 disabled:bg-transparent aria-disabled:bg-transparent dark:hover:bg-sky-400/10 dark:focus-visible:bg-sky-400/10 dark:disabled:bg-transparent dark:aria-disabled:bg-transparent"
    },
    {
      "color": "blue" as const,
      "variant": "ghost" as const,
      "class": "text-blue-500 dark:text-blue-400 hover:bg-blue-500/10 focus-visible:bg-blue-500/10 disabled:bg-transparent aria-disabled:bg-transparent dark:hover:bg-blue-400/10 dark:focus-visible:bg-blue-400/10 dark:disabled:bg-transparent dark:aria-disabled:bg-transparent"
    },
    {
      "color": "indigo" as const,
      "variant": "ghost" as const,
      "class": "text-indigo-500 dark:text-indigo-400 hover:bg-indigo-500/10 focus-visible:bg-indigo-500/10 disabled:bg-transparent aria-disabled:bg-transparent dark:hover:bg-indigo-400/10 dark:focus-visible:bg-indigo-400/10 dark:disabled:bg-transparent dark:aria-disabled:bg-transparent"
    },
    {
      "color": "violet" as const,
      "variant": "ghost" as const,
      "class": "text-violet-500 dark:text-violet-400 hover:bg-violet-500/10 focus-visible:bg-violet-500/10 disabled:bg-transparent aria-disabled:bg-transparent dark:hover:bg-violet-400/10 dark:focus-visible:bg-violet-400/10 dark:disabled:bg-transparent dark:aria-disabled:bg-transparent"
    },
    {
      "color": "purple" as const,
      "variant": "ghost" as const,
      "class": "text-purple-500 dark:text-purple-400 hover:bg-purple-500/10 focus-visible:bg-purple-500/10 disabled:bg-transparent aria-disabled:bg-transparent dark:hover:bg-purple-400/10 dark:focus-visible:bg-purple-400/10 dark:disabled:bg-transparent dark:aria-disabled:bg-transparent"
    },
    {
      "color": "fuchsia" as const,
      "variant": "ghost" as const,
      "class": "text-fuchsia-500 dark:text-fuchsia-400 hover:bg-fuchsia-500/10 focus-visible:bg-fuchsia-500/10 disabled:bg-transparent aria-disabled:bg-transparent dark:hover:bg-fuchsia-400/10 dark:focus-visible:bg-fuchsia-400/10 dark:disabled:bg-transparent dark:aria-disabled:bg-transparent"
    },
    {
      "color": "pink" as const,
      "variant": "ghost" as const,
      "class": "text-pink-500 dark:text-pink-400 hover:bg-pink-500/10 focus-visible:bg-pink-500/10 disabled:bg-transparent aria-disabled:bg-transparent dark:hover:bg-pink-400/10 dark:focus-visible:bg-pink-400/10 dark:disabled:bg-transparent dark:aria-disabled:bg-transparent"
    },
    {
      "color": "rose" as const,
      "variant": "ghost" as const,
      "class": "text-rose-500 dark:text-rose-400 hover:bg-rose-500/10 focus-visible:bg-rose-500/10 disabled:bg-transparent aria-disabled:bg-transparent dark:hover:bg-rose-400/10 dark:focus-visible:bg-rose-400/10 dark:disabled:bg-transparent dark:aria-disabled:bg-transparent"
    },
    {
      "color": "primary" as const,
      "variant": "link" as const,
      "class": "text-primary-500 hover:text-primary-600 disabled:text-primary-500 aria-disabled:text-primary-500 dark:text-primary-400 dark:hover:text-primary-500 dark:disabled:text-primary-400 dark:aria-disabled:text-primary-400 focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-primary-500 dark:focus-visible:ring-primary-400"
    },
    {
      "color": "error" as const,
      "variant": "link" as const,
      "class": "text-error-500 hover:text-error-600 disabled:text-error-500 aria-disabled:text-error-500 dark:text-error-400 dark:hover:text-error-500 dark:disabled:text-error-400 dark:aria-disabled:text-error-400 focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-error-500 dark:focus-visible:ring-error-400"
    },
    {
      "color": "red" as const,
      "variant": "link" as const,
      "class": "text-red-500 hover:text-red-600 disabled:text-red-500 aria-disabled:text-red-500 dark:text-red-400 dark:hover:text-red-500 dark:disabled:text-red-400 dark:aria-disabled:text-red-400 focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-red-500 dark:focus-visible:ring-red-400"
    },
    {
      "color": "orange" as const,
      "variant": "link" as const,
      "class": "text-orange-500 hover:text-orange-600 disabled:text-orange-500 aria-disabled:text-orange-500 dark:text-orange-400 dark:hover:text-orange-500 dark:disabled:text-orange-400 dark:aria-disabled:text-orange-400 focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-orange-500 dark:focus-visible:ring-orange-400"
    },
    {
      "color": "amber" as const,
      "variant": "link" as const,
      "class": "text-amber-500 hover:text-amber-600 disabled:text-amber-500 aria-disabled:text-amber-500 dark:text-amber-400 dark:hover:text-amber-500 dark:disabled:text-amber-400 dark:aria-disabled:text-amber-400 focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-amber-500 dark:focus-visible:ring-amber-400"
    },
    {
      "color": "yellow" as const,
      "variant": "link" as const,
      "class": "text-yellow-500 hover:text-yellow-600 disabled:text-yellow-500 aria-disabled:text-yellow-500 dark:text-yellow-400 dark:hover:text-yellow-500 dark:disabled:text-yellow-400 dark:aria-disabled:text-yellow-400 focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-yellow-500 dark:focus-visible:ring-yellow-400"
    },
    {
      "color": "lime" as const,
      "variant": "link" as const,
      "class": "text-lime-500 hover:text-lime-600 disabled:text-lime-500 aria-disabled:text-lime-500 dark:text-lime-400 dark:hover:text-lime-500 dark:disabled:text-lime-400 dark:aria-disabled:text-lime-400 focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-lime-500 dark:focus-visible:ring-lime-400"
    },
    {
      "color": "green" as const,
      "variant": "link" as const,
      "class": "text-green-500 hover:text-green-600 disabled:text-green-500 aria-disabled:text-green-500 dark:text-green-400 dark:hover:text-green-500 dark:disabled:text-green-400 dark:aria-disabled:text-green-400 focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-green-500 dark:focus-visible:ring-green-400"
    },
    {
      "color": "emerald" as const,
      "variant": "link" as const,
      "class": "text-emerald-500 hover:text-emerald-600 disabled:text-emerald-500 aria-disabled:text-emerald-500 dark:text-emerald-400 dark:hover:text-emerald-500 dark:disabled:text-emerald-400 dark:aria-disabled:text-emerald-400 focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-emerald-500 dark:focus-visible:ring-emerald-400"
    },
    {
      "color": "teal" as const,
      "variant": "link" as const,
      "class": "text-teal-500 hover:text-teal-600 disabled:text-teal-500 aria-disabled:text-teal-500 dark:text-teal-400 dark:hover:text-teal-500 dark:disabled:text-teal-400 dark:aria-disabled:text-teal-400 focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-teal-500 dark:focus-visible:ring-teal-400"
    },
    {
      "color": "cyan" as const,
      "variant": "link" as const,
      "class": "text-cyan-500 hover:text-cyan-600 disabled:text-cyan-500 aria-disabled:text-cyan-500 dark:text-cyan-400 dark:hover:text-cyan-500 dark:disabled:text-cyan-400 dark:aria-disabled:text-cyan-400 focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-cyan-500 dark:focus-visible:ring-cyan-400"
    },
    {
      "color": "sky" as const,
      "variant": "link" as const,
      "class": "text-sky-500 hover:text-sky-600 disabled:text-sky-500 aria-disabled:text-sky-500 dark:text-sky-400 dark:hover:text-sky-500 dark:disabled:text-sky-400 dark:aria-disabled:text-sky-400 focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-sky-500 dark:focus-visible:ring-sky-400"
    },
    {
      "color": "blue" as const,
      "variant": "link" as const,
      "class": "text-blue-500 hover:text-blue-600 disabled:text-blue-500 aria-disabled:text-blue-500 dark:text-blue-400 dark:hover:text-blue-500 dark:disabled:text-blue-400 dark:aria-disabled:text-blue-400 focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-blue-500 dark:focus-visible:ring-blue-400"
    },
    {
      "color": "indigo" as const,
      "variant": "link" as const,
      "class": "text-indigo-500 hover:text-indigo-600 disabled:text-indigo-500 aria-disabled:text-indigo-500 dark:text-indigo-400 dark:hover:text-indigo-500 dark:disabled:text-indigo-400 dark:aria-disabled:text-indigo-400 focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-indigo-500 dark:focus-visible:ring-indigo-400"
    },
    {
      "color": "violet" as const,
      "variant": "link" as const,
      "class": "text-violet-500 hover:text-violet-600 disabled:text-violet-500 aria-disabled:text-violet-500 dark:text-violet-400 dark:hover:text-violet-500 dark:disabled:text-violet-400 dark:aria-disabled:text-violet-400 focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-violet-500 dark:focus-visible:ring-violet-400"
    },
    {
      "color": "purple" as const,
      "variant": "link" as const,
      "class": "text-purple-500 hover:text-purple-600 disabled:text-purple-500 aria-disabled:text-purple-500 dark:text-purple-400 dark:hover:text-purple-500 dark:disabled:text-purple-400 dark:aria-disabled:text-purple-400 focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-purple-500 dark:focus-visible:ring-purple-400"
    },
    {
      "color": "fuchsia" as const,
      "variant": "link" as const,
      "class": "text-fuchsia-500 hover:text-fuchsia-600 disabled:text-fuchsia-500 aria-disabled:text-fuchsia-500 dark:text-fuchsia-400 dark:hover:text-fuchsia-500 dark:disabled:text-fuchsia-400 dark:aria-disabled:text-fuchsia-400 focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-fuchsia-500 dark:focus-visible:ring-fuchsia-400"
    },
    {
      "color": "pink" as const,
      "variant": "link" as const,
      "class": "text-pink-500 hover:text-pink-600 disabled:text-pink-500 aria-disabled:text-pink-500 dark:text-pink-400 dark:hover:text-pink-500 dark:disabled:text-pink-400 dark:aria-disabled:text-pink-400 focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-pink-500 dark:focus-visible:ring-pink-400"
    },
    {
      "color": "rose" as const,
      "variant": "link" as const,
      "class": "text-rose-500 hover:text-rose-600 disabled:text-rose-500 aria-disabled:text-rose-500 dark:text-rose-400 dark:hover:text-rose-500 dark:disabled:text-rose-400 dark:aria-disabled:text-rose-400 focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-rose-500 dark:focus-visible:ring-rose-400"
    },
    {
      "color": "gray" as const,
      "variant": "solid" as const,
      "class": "text-white dark:text-gray-900 bg-gray-900 hover:bg-gray-700 disabled:bg-gray-900 aria-disabled:bg-gray-900 dark:bg-white dark:hover:bg-gray-200 dark:disabled:bg-white dark:aria-disabled:bg-white focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900 dark:focus-visible:outline-white"
    },
    {
      "color": "gray" as const,
      "variant": "outline" as const,
      "class": "ring ring-inset ring-gray-300 dark:ring-gray-700 text-gray-700 dark:text-gray-200 bg-white hover:bg-gray-100 disabled:bg-white aria-disabled:bg-white dark:bg-gray-900 dark:hover:bg-gray-800 dark:disabled:bg-gray-900 dark:aria-disabled:bg-gray-900 focus-visible:ring-2 focus-visible:ring-gray-900 dark:focus-visible:ring-white"
    },
    {
      "color": "gray" as const,
      "variant": "soft" as const,
      "class": "text-gray-700 dark:text-gray-200 bg-gray-100 hover:bg-gray-200 focus-visible:bg-gray-200 disabled:bg-gray-100 aria-disabled:bg-gray-100 dark:bg-gray-800 dark:hover:bg-gray-700/50 dark:focus-visible:bg-gray-700/50 dark:disabled:bg-gray-800 dark:aria-disabled:bg-gray-800"
    },
    {
      "color": "gray" as const,
      "variant": "subtle" as const,
      "class": "ring ring-inset ring-gray-300 dark:ring-gray-700 text-gray-700 dark:text-gray-200 bg-gray-100 hover:bg-gray-200 disabled:bg-gray-100 aria-disabled:bg-gray-100 dark:bg-gray-800 dark:hover:bg-gray-700/50 dark:disabled:bg-gray-800 dark:aria-disabled:bg-gray-800 focus-visible:ring-2 focus-visible:ring-gray-900 dark:focus-visible:ring-white"
    },
    {
      "color": "gray" as const,
      "variant": "ghost" as const,
      "class": "text-gray-700 dark:text-gray-200 hover:bg-gray-100 focus-visible:bg-gray-100 disabled:bg-transparent aria-disabled:bg-transparent dark:hover:bg-gray-800 dark:focus-visible:bg-gray-800 dark:hover:disabled:bg-transparent dark:hover:aria-disabled:bg-transparent"
    },
    {
      "color": "gray" as const,
      "variant": "link" as const,
      "class": "text-gray-500 hover:text-gray-700 disabled:text-gray-500 aria-disabled:text-gray-500 dark:text-gray-400 dark:hover:text-gray-200 dark:disabled:text-gray-400 dark:aria-disabled:text-gray-400 focus-visible:ring-inset focus-visible:ring-2 focus-visible:ring-gray-900 dark:focus-visible:ring-white"
    },
    {
      "size": "xs" as const,
      "square": true,
      "class": "p-1"
    },
    {
      "size": "sm" as const,
      "square": true,
      "class": "p-1.5"
    },
    {
      "size": "md" as const,
      "square": true,
      "class": "p-1.5"
    },
    {
      "size": "lg" as const,
      "square": true,
      "class": "p-2"
    },
    {
      "size": "xl" as const,
      "square": true,
      "class": "p-2"
    },
    {
      "loading": true,
      "leading": true,
      "class": {
        "leadingIcon": "animate-spin"
      }
    },
    {
      "loading": true,
      "leading": false,
      "trailing": true,
      "class": {
        "trailingIcon": "animate-spin"
      }
    }
  ],
  "defaultVariants": {
    "color": "primary" as const,
    "variant": "solid" as const,
    "size": "md" as const
  }
}