<template>
  <NuxtRouteAnnouncer />
  <UApp v-show="showIonApp">
    <ion-app>
      <ion-router-outlet
        id="router-outlet"
        :animated="Capacitor.isNativePlatform()"
      />
    </ion-app>
  </UApp>
</template>

<script setup lang="ts">
import { Capacitor } from '@capacitor/core'
import { SplashScreen } from '@capacitor/splash-screen'
import { Revolut } from 'capacitor-plugin-revolut'

if (import.meta.server) {
  defineOgImageComponent('UpfanOG', {
    theme: '#ff8000',
    logo: '/upfan.svg',
  })
}

const { public: { revolut } } = useRuntimeConfig()

const authStore = useAuthStore()
await authStore.me()

onMounted(async () => {
  await Revolut.initialize({
    token: revolut.publicKey,
    environment: 'sandbox',
  })
})

// remove server-side rendered ionic app
const showIonApp = ref(false)
onPrehydrate(() => document.getElementById('router-outlet')!.innerHTML = '')
showIonApp.value = true
SplashScreen.hide()
</script>

<style>
</style>
