import revive_payload_client_bRSdyOcCw3 from "/workspace/node_modules/.pnpm/nuxt@3.13.2_@capacitor+preferences@6.0.2_@capacitor+core@6.1.2__@parcel+watcher@2.4.1_@types+_oa5s6bx5yl3z4pqndbsvzjqkyy/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_AloRLzyc2T from "/workspace/node_modules/.pnpm/nuxt@3.13.2_@capacitor+preferences@6.0.2_@capacitor+core@6.1.2__@parcel+watcher@2.4.1_@types+_oa5s6bx5yl3z4pqndbsvzjqkyy/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_qqE6qjgECV from "/workspace/node_modules/.pnpm/@nuxtjs+ionic@0.15.1_magicast@0.3.5_rollup@4.22.4_vue@3.5.8_typescript@5.6.2__webpack-sources@3.2.3/node_modules/@nuxtjs/ionic/dist/runtime/plugins/router.js";
import _0_siteConfig_hLGtKSNh9e from "/workspace/node_modules/.pnpm/nuxt-site-config@2.2.18_magicast@0.3.5_rollup@4.22.4_vite@5.4.8_@types+node@22.7.2_lightningc_exn56vlp76ufokrwdbq77p5z7m/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_XPx9KUjoDW from "/workspace/node_modules/.pnpm/nuxt@3.13.2_@capacitor+preferences@6.0.2_@capacitor+core@6.1.2__@parcel+watcher@2.4.1_@types+_oa5s6bx5yl3z4pqndbsvzjqkyy/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_pB8JmXahG2 from "/workspace/node_modules/.pnpm/nuxt@3.13.2_@capacitor+preferences@6.0.2_@capacitor+core@6.1.2__@parcel+watcher@2.4.1_@types+_oa5s6bx5yl3z4pqndbsvzjqkyy/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_k7gEsW989I from "/workspace/node_modules/.pnpm/nuxt@3.13.2_@capacitor+preferences@6.0.2_@capacitor+core@6.1.2__@parcel+watcher@2.4.1_@types+_oa5s6bx5yl3z4pqndbsvzjqkyy/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_Q4Ozr5WQxY from "/workspace/node_modules/.pnpm/nuxt@3.13.2_@capacitor+preferences@6.0.2_@capacitor+core@6.1.2__@parcel+watcher@2.4.1_@types+_oa5s6bx5yl3z4pqndbsvzjqkyy/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_VQIxS4wqOh from "/workspace/node_modules/.pnpm/@pinia+nuxt@0.5.4_magicast@0.3.5_rollup@4.22.4_typescript@5.6.2_vue@3.5.8_typescript@5.6.2__webpack-sources@3.2.3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/workspace/.nuxt/components.plugin.mjs";
import prefetch_client_d0qQsjmfyL from "/workspace/node_modules/.pnpm/nuxt@3.13.2_@capacitor+preferences@6.0.2_@capacitor+core@6.1.2__@parcel+watcher@2.4.1_@types+_oa5s6bx5yl3z4pqndbsvzjqkyy/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import components_client_tnrmVIVQde from "/workspace/node_modules/.pnpm/nuxt-swiper@2.0.0-1_magicast@0.3.5_rollup@4.22.4_webpack-sources@3.2.3/node_modules/nuxt-swiper/dist/runtime/plugins/components.client.mjs";
import ionic_VcspYNa41q from "/workspace/node_modules/.pnpm/@nuxtjs+ionic@0.15.1_magicast@0.3.5_rollup@4.22.4_vue@3.5.8_typescript@5.6.2__webpack-sources@3.2.3/node_modules/@nuxtjs/ionic/dist/runtime/plugins/ionic.js";
import titles_KkYB0CzPau from "/workspace/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.21_h3@1.12.0_magicast@0.3.5_rollup@4.22.4_vite@5.4.8_@types+node@22.7.2__aexhds5qjgyqxpvdqbcpopd6dy/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.js";
import defaultsWaitI18n_ztS9aR5Mdx from "/workspace/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.21_h3@1.12.0_magicast@0.3.5_rollup@4.22.4_vite@5.4.8_@types+node@22.7.2__aexhds5qjgyqxpvdqbcpopd6dy/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/defaultsWaitI18n.js";
import siteConfig_qlbiKx3uJ6 from "/workspace/node_modules/.pnpm/nuxt-seo-experiments@4.0.1_magicast@0.3.5_rollup@4.22.4_vite@5.4.8_@types+node@22.7.2_lightni_2zymecyraknhp5d3e5pcqsa4km/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.js";
import inferSeoMetaPlugin_8HzNCntBFm from "/workspace/node_modules/.pnpm/nuxt-seo-experiments@4.0.1_magicast@0.3.5_rollup@4.22.4_vite@5.4.8_@types+node@22.7.2_lightni_2zymecyraknhp5d3e5pcqsa4km/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.js";
import switch_locale_path_ssr_Q5FEyoGFul from "/workspace/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_magicast@0.3.5_rollup@4.22.4_vue@3.5.8_typescript@5.6.2__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_2QpbkkvnqS from "/workspace/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_magicast@0.3.5_rollup@4.22.4_vue@3.5.8_typescript@5.6.2__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import slideover_uH9edF4kAI from "/workspace/node_modules/.pnpm/@nuxt+ui@3.0.0-alpha.5_@capacitor+preferences@6.0.2_@capacitor+core@6.1.2__ioredis@5.4.1_magi_rplynwi2uebdunztquirtpx3ai/node_modules/@nuxt/ui/dist/runtime/plugins/slideover.js";
import modal_l93p7jsxUn from "/workspace/node_modules/.pnpm/@nuxt+ui@3.0.0-alpha.5_@capacitor+preferences@6.0.2_@capacitor+core@6.1.2__ioredis@5.4.1_magi_rplynwi2uebdunztquirtpx3ai/node_modules/@nuxt/ui/dist/runtime/plugins/modal.js";
import colors_nF6nEFA7J4 from "/workspace/node_modules/.pnpm/@nuxt+ui@3.0.0-alpha.5_@capacitor+preferences@6.0.2_@capacitor+core@6.1.2__ioredis@5.4.1_magi_rplynwi2uebdunztquirtpx3ai/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_dlry8Kmpr6 from "/workspace/node_modules/.pnpm/@nuxtjs+color-mode@3.5.1_magicast@0.3.5_rollup@4.22.4_webpack-sources@3.2.3/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_JVUbiBzCtW from "/workspace/node_modules/.pnpm/@nuxt+icon@1.5.4_magicast@0.3.5_rollup@4.22.4_vite@5.4.8_@types+node@22.7.2_lightningcss@1.27_7c3v3ii4y6jwazw3vm32ax456m/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import plugin_uSc6jyEBQ4 from "/workspace/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.1_@pinia+nuxt@0.5.4_magicast@0.3.5_rollup@4.22.4_typesc_tnugeua74loooxogk7smnkrndu/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import api_slUmq5TON0 from "/workspace/app/plugins/api.ts";
import lightGallery_HN2IP0FRJ5 from "/workspace/app/plugins/lightGallery.ts";
import luxon_JWHRGPoQ6G from "/workspace/app/plugins/luxon.ts";
import posthog_client_8TXiKjlKOS from "/workspace/app/plugins/posthog.client.ts";
import sentry_client_a4hAB0MOGi from "/workspace/app/plugins/sentry.client.ts";
export default [
  revive_payload_client_bRSdyOcCw3,
  unhead_AloRLzyc2T,
  router_qqE6qjgECV,
  _0_siteConfig_hLGtKSNh9e,
  payload_client_XPx9KUjoDW,
  navigation_repaint_client_pB8JmXahG2,
  check_outdated_build_client_k7gEsW989I,
  chunk_reload_client_Q4Ozr5WQxY,
  plugin_vue3_VQIxS4wqOh,
  components_plugin_KR1HBZs4kY,
  prefetch_client_d0qQsjmfyL,
  components_client_tnrmVIVQde,
  ionic_VcspYNa41q,
  titles_KkYB0CzPau,
  defaultsWaitI18n_ztS9aR5Mdx,
  siteConfig_qlbiKx3uJ6,
  inferSeoMetaPlugin_8HzNCntBFm,
  switch_locale_path_ssr_Q5FEyoGFul,
  i18n_2QpbkkvnqS,
  slideover_uH9edF4kAI,
  modal_l93p7jsxUn,
  colors_nF6nEFA7J4,
  plugin_client_dlry8Kmpr6,
  plugin_JVUbiBzCtW,
  plugin_uSc6jyEBQ4,
  api_slUmq5TON0,
  lightGallery_HN2IP0FRJ5,
  luxon_JWHRGPoQ6G,
  posthog_client_8TXiKjlKOS,
  sentry_client_a4hAB0MOGi
]