export default {
  "slots": {
    "root": "relative group overflow-hidden bg-white dark:bg-gray-900 shadow-lg rounded-lg ring ring-gray-200 dark:ring-gray-800 p-4 flex gap-2.5 focus:outline-none",
    "wrapper": "w-0 flex-1 flex flex-col gap-1",
    "title": "text-sm font-medium text-gray-900 dark:text-white",
    "description": "text-sm text-gray-500 dark:text-gray-400",
    "icon": "shrink-0 size-5",
    "avatar": "shrink-0",
    "avatarSize": "2xl",
    "actions": "flex gap-1.5 shrink-0",
    "progress": "absolute inset-x-0 bottom-0 h-1 z-[-1]",
    "close": "p-0.5"
  },
  "variants": {
    "color": {
      "primary": {
        "root": "focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-primary-500 dark:focus-visible:ring-primary-400",
        "icon": "text-primary-500 dark:text-primary-400",
        "progress": "bg-primary-500 dark:bg-primary-400"
      },
      "error": {
        "root": "focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-error-500 dark:focus-visible:ring-error-400",
        "icon": "text-error-500 dark:text-error-400",
        "progress": "bg-error-500 dark:bg-error-400"
      },
      "red": {
        "root": "focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-red-500 dark:focus-visible:ring-red-400",
        "icon": "text-red-500 dark:text-red-400",
        "progress": "bg-red-500 dark:bg-red-400"
      },
      "orange": {
        "root": "focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-orange-500 dark:focus-visible:ring-orange-400",
        "icon": "text-orange-500 dark:text-orange-400",
        "progress": "bg-orange-500 dark:bg-orange-400"
      },
      "amber": {
        "root": "focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-amber-500 dark:focus-visible:ring-amber-400",
        "icon": "text-amber-500 dark:text-amber-400",
        "progress": "bg-amber-500 dark:bg-amber-400"
      },
      "yellow": {
        "root": "focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-yellow-500 dark:focus-visible:ring-yellow-400",
        "icon": "text-yellow-500 dark:text-yellow-400",
        "progress": "bg-yellow-500 dark:bg-yellow-400"
      },
      "lime": {
        "root": "focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-lime-500 dark:focus-visible:ring-lime-400",
        "icon": "text-lime-500 dark:text-lime-400",
        "progress": "bg-lime-500 dark:bg-lime-400"
      },
      "green": {
        "root": "focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-green-500 dark:focus-visible:ring-green-400",
        "icon": "text-green-500 dark:text-green-400",
        "progress": "bg-green-500 dark:bg-green-400"
      },
      "emerald": {
        "root": "focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-emerald-500 dark:focus-visible:ring-emerald-400",
        "icon": "text-emerald-500 dark:text-emerald-400",
        "progress": "bg-emerald-500 dark:bg-emerald-400"
      },
      "teal": {
        "root": "focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-teal-500 dark:focus-visible:ring-teal-400",
        "icon": "text-teal-500 dark:text-teal-400",
        "progress": "bg-teal-500 dark:bg-teal-400"
      },
      "cyan": {
        "root": "focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-cyan-500 dark:focus-visible:ring-cyan-400",
        "icon": "text-cyan-500 dark:text-cyan-400",
        "progress": "bg-cyan-500 dark:bg-cyan-400"
      },
      "sky": {
        "root": "focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-sky-500 dark:focus-visible:ring-sky-400",
        "icon": "text-sky-500 dark:text-sky-400",
        "progress": "bg-sky-500 dark:bg-sky-400"
      },
      "blue": {
        "root": "focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-blue-500 dark:focus-visible:ring-blue-400",
        "icon": "text-blue-500 dark:text-blue-400",
        "progress": "bg-blue-500 dark:bg-blue-400"
      },
      "indigo": {
        "root": "focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-indigo-500 dark:focus-visible:ring-indigo-400",
        "icon": "text-indigo-500 dark:text-indigo-400",
        "progress": "bg-indigo-500 dark:bg-indigo-400"
      },
      "violet": {
        "root": "focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-violet-500 dark:focus-visible:ring-violet-400",
        "icon": "text-violet-500 dark:text-violet-400",
        "progress": "bg-violet-500 dark:bg-violet-400"
      },
      "purple": {
        "root": "focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-purple-500 dark:focus-visible:ring-purple-400",
        "icon": "text-purple-500 dark:text-purple-400",
        "progress": "bg-purple-500 dark:bg-purple-400"
      },
      "fuchsia": {
        "root": "focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-fuchsia-500 dark:focus-visible:ring-fuchsia-400",
        "icon": "text-fuchsia-500 dark:text-fuchsia-400",
        "progress": "bg-fuchsia-500 dark:bg-fuchsia-400"
      },
      "pink": {
        "root": "focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-pink-500 dark:focus-visible:ring-pink-400",
        "icon": "text-pink-500 dark:text-pink-400",
        "progress": "bg-pink-500 dark:bg-pink-400"
      },
      "rose": {
        "root": "focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-rose-500 dark:focus-visible:ring-rose-400",
        "icon": "text-rose-500 dark:text-rose-400",
        "progress": "bg-rose-500 dark:bg-rose-400"
      },
      "gray": {
        "root": "focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-gray-900 dark:focus-visible:ring-white",
        "icon": "text-gray-900 dark:text-white",
        "progress": "bg-gray-900 dark:bg-white"
      }
    },
    "multiline": {
      "true": {
        "root": "items-start",
        "actions": "items-start mt-1"
      },
      "false": {
        "root": "items-center",
        "actions": "items-center"
      }
    }
  },
  "defaultVariants": {
    "color": "primary" as const
  }
}