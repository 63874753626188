import { default as indexRZJHGTDvaLMeta } from "/workspace/app/pages/about/index.vue?macro=true";
import { default as indexUpzFriVyjOMeta } from "/workspace/app/pages/event/[id]/cart/index.vue?macro=true";
import { default as indexJo4cm8FiKRMeta } from "/workspace/app/pages/event/[id]/index.vue?macro=true";
import { default as signinWsQEMtkBkjMeta } from "/workspace/app/pages/index/auth/signin.vue?macro=true";
import { default as signup63ot4ccbdkMeta } from "/workspace/app/pages/index/auth/signup.vue?macro=true";
import { default as indexW5wJ5kgK0jMeta } from "/workspace/app/pages/index/events/index.vue?macro=true";
import { default as indexnHWnf6Yy1wMeta } from "/workspace/app/pages/index/index.vue?macro=true";
import { default as indexEKLyyakMqcMeta } from "/workspace/app/pages/index/profile/index.vue?macro=true";
import { default as indextewX3x9t0yMeta } from "/workspace/app/pages/index/tickets/index.vue?macro=true";
import { default as index6n3ZPBUsMaMeta } from "/workspace/app/pages/index.vue?macro=true";
import { default as indexde4LDRdPaEMeta } from "/workspace/app/pages/terms/index.vue?macro=true";
import { default as component_45stubbL7W9W5acpMeta } from "/workspace/node_modules/.pnpm/nuxt@3.13.2_@capacitor+preferences@6.0.2_@capacitor+core@6.1.2__@parcel+watcher@2.4.1_@types+_oa5s6bx5yl3z4pqndbsvzjqkyy/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubbL7W9W5acp } from "/workspace/node_modules/.pnpm/nuxt@3.13.2_@capacitor+preferences@6.0.2_@capacitor+core@6.1.2__@parcel+watcher@2.4.1_@types+_oa5s6bx5yl3z4pqndbsvzjqkyy/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "about___sk",
    path: "/o-nas",
    component: () => import("/workspace/app/pages/about/index.vue")
  },
  {
    name: "about___cs",
    path: "/cs/o-nas",
    component: () => import("/workspace/app/pages/about/index.vue")
  },
  {
    name: "about___en",
    path: "/en/about",
    component: () => import("/workspace/app/pages/about/index.vue")
  },
  {
    name: "event-id-cart___sk",
    path: "/podujatie/:id()/kosik",
    component: () => import("/workspace/app/pages/event/[id]/cart/index.vue")
  },
  {
    name: "event-id-cart___cs",
    path: "/cs/udalost/:id()/kosik",
    component: () => import("/workspace/app/pages/event/[id]/cart/index.vue")
  },
  {
    name: "event-id-cart___en",
    path: "/en/event/:id()/cart",
    component: () => import("/workspace/app/pages/event/[id]/cart/index.vue")
  },
  {
    name: "event-id___sk",
    path: "/podujatie/:id()",
    component: () => import("/workspace/app/pages/event/[id]/index.vue")
  },
  {
    name: "event-id___cs",
    path: "/cs/udalost/:id()",
    component: () => import("/workspace/app/pages/event/[id]/index.vue")
  },
  {
    name: "event-id___en",
    path: "/en/event/:id()",
    component: () => import("/workspace/app/pages/event/[id]/index.vue")
  },
  {
    name: index6n3ZPBUsMaMeta?.name,
    path: "/",
    component: () => import("/workspace/app/pages/index.vue"),
    children: [
  {
    name: "index-auth-signin___sk",
    path: "/prihlasenie",
    meta: signinWsQEMtkBkjMeta || {},
    component: () => import("/workspace/app/pages/index/auth/signin.vue")
  },
  {
    name: "index-auth-signup___sk",
    path: "/registracia",
    meta: signup63ot4ccbdkMeta || {},
    component: () => import("/workspace/app/pages/index/auth/signup.vue")
  },
  {
    name: "index-events___sk",
    path: "/podujatia",
    component: () => import("/workspace/app/pages/index/events/index.vue")
  },
  {
    name: "index___sk",
    path: "/",
    component: () => import("/workspace/app/pages/index/index.vue")
  },
  {
    name: "index-profile___sk",
    path: "/profil",
    meta: indexEKLyyakMqcMeta || {},
    component: () => import("/workspace/app/pages/index/profile/index.vue")
  },
  {
    name: "index-tickets___sk",
    path: "/vstupenky",
    component: () => import("/workspace/app/pages/index/tickets/index.vue")
  }
]
  },
  {
    name: index6n3ZPBUsMaMeta?.name,
    path: "/cs",
    component: () => import("/workspace/app/pages/index.vue"),
    children: [
  {
    name: "index-auth-signin___cs",
    path: "prihlaseni",
    meta: signinWsQEMtkBkjMeta || {},
    component: () => import("/workspace/app/pages/index/auth/signin.vue")
  },
  {
    name: "index-auth-signup___cs",
    path: "registrace",
    meta: signup63ot4ccbdkMeta || {},
    component: () => import("/workspace/app/pages/index/auth/signup.vue")
  },
  {
    name: "index-events___cs",
    path: "udalosti",
    component: () => import("/workspace/app/pages/index/events/index.vue")
  },
  {
    name: "index___cs",
    path: "/cs",
    component: () => import("/workspace/app/pages/index/index.vue")
  },
  {
    name: "index-profile___cs",
    path: "profil",
    meta: indexEKLyyakMqcMeta || {},
    component: () => import("/workspace/app/pages/index/profile/index.vue")
  },
  {
    name: "index-tickets___cs",
    path: "vstupenky",
    component: () => import("/workspace/app/pages/index/tickets/index.vue")
  }
]
  },
  {
    name: index6n3ZPBUsMaMeta?.name,
    path: "/en",
    component: () => import("/workspace/app/pages/index.vue"),
    children: [
  {
    name: "index-auth-signin___en",
    path: "signin",
    meta: signinWsQEMtkBkjMeta || {},
    component: () => import("/workspace/app/pages/index/auth/signin.vue")
  },
  {
    name: "index-auth-signup___en",
    path: "signup",
    meta: signup63ot4ccbdkMeta || {},
    component: () => import("/workspace/app/pages/index/auth/signup.vue")
  },
  {
    name: "index-events___en",
    path: "events",
    component: () => import("/workspace/app/pages/index/events/index.vue")
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/workspace/app/pages/index/index.vue")
  },
  {
    name: "index-profile___en",
    path: "profile",
    meta: indexEKLyyakMqcMeta || {},
    component: () => import("/workspace/app/pages/index/profile/index.vue")
  },
  {
    name: "index-tickets___en",
    path: "tickets",
    component: () => import("/workspace/app/pages/index/tickets/index.vue")
  }
]
  },
  {
    name: "terms___sk",
    path: "/obchodne-podmienky",
    component: () => import("/workspace/app/pages/terms/index.vue")
  },
  {
    name: "terms___cs",
    path: "/cs/obchodni-podminky",
    component: () => import("/workspace/app/pages/terms/index.vue")
  },
  {
    name: "terms___en",
    path: "/en/terms",
    component: () => import("/workspace/app/pages/terms/index.vue")
  },
  {
    name: component_45stubbL7W9W5acpMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubbL7W9W5acp
  },
  {
    name: component_45stubbL7W9W5acpMeta?.name,
    path: "/index-sitemap.xml",
    component: component_45stubbL7W9W5acp
  },
  {
    name: component_45stubbL7W9W5acpMeta?.name,
    path: "/sk-SK-sitemap.xml",
    component: component_45stubbL7W9W5acp
  },
  {
    name: component_45stubbL7W9W5acpMeta?.name,
    path: "/cs-CZ-sitemap.xml",
    component: component_45stubbL7W9W5acp
  },
  {
    name: component_45stubbL7W9W5acpMeta?.name,
    path: "/en-US-sitemap.xml",
    component: component_45stubbL7W9W5acp
  }
]